import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip, Form, Switch, Spin, Checkbox } from 'antd';
import Box from '@iso/components/utility/box';
import { Link } from 'react-router-dom';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import Input from '@iso/containers/Deployments/ApplicationBasicDetails.styles';
// import Input, {
//     InputSearch,
//     InputGroup,
//     Textarea,
// } from '@iso/components/uielements/input';
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
import awsLogo from '../../../assets/images/aws.svg';
import customScriptIcon from '@iso/assets/images/custom-script-icon.svg';
import secretDetectionIcon from '@iso/assets/images/secret-detection-icon.svg';
import sonarcubeIcon from '@iso/assets/images/sonarcube-icon.svg';
import checkmarkIcon from '@iso/assets/images/checkmark-icon.svg';
import { customFilterOption, customSortOptions, formatErrors, marketplaceStatuses, timeSince } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import noDataPlusIcon from '@iso/assets/images/nodata-plus-icon.svg';
import noDataImg from '@iso/assets/images/no-data-found.svg';
import deleteIcon from '@iso/assets/images/tagsDelete.svg';
import plusIcon from '@iso/assets/images/plusIcon.svg';
import Select from '@iso/containers/Deployments/Deployment.styles';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import { fetchBranches } from '../../../apis/Integrations';


const Option = SelectOption;

let divContainerStyle = {
    display: "flex",
    width: "75%",
    justifyContent: "space-between",
    marginTop: "40px"
}

let boxStyle = {
    border: "transparent",
    width: "fit-content",
    padding: "0",
    margin: "0",
}
let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "1px",
};

let innerSubtitleStyle = {
    fontSize: "14px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontType: "regular",
    fontWeight: "400",
    lineHeight: "14px"
}

let boxTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "1px",
};

let boxSubtitleStyle = {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

let inputStyle = {
    padding: '4px 11px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontSize: '14px',
    borderRadius: '6px',
    height: "40px"
}

const widgetStyle = {
    margin: "20px",
    width: "100%",
};

export default ({
    workflowNodeData,
    workflowEdgesData,
    columnId,
}) => {

    const { openRightDrawer, setOpenRightDrawer, workflowData, setWorkflowData, currentWorkflowData, setCurrentWorkflowData } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const navigate = useNavigate();
    // const location = useLocation();

    const [form] = Form.useForm();
    const repoUrlRegex = /(?:https?:\/\/)?(?:www\.)?(?:github\.com|gitlab\.com)\/([^/\s]+)\/([^/\s]+)/i;

    const [buildData, setBuildData] = useState({
        // dockerRepo: "",
        dockerRepoUrl: "",
        containerRegistry: "",
        containerRegistryId: "",
        dockerfilePath: "",
        region: "",
        accountId: "",
        alias: "",
        integrationType: "",
        buildContext: "",
        logo: "",
        platform: "amd",
        cpu: "",
        ram: "",
        volume: 20,
        spot: false,
        cacheEnabled: false,
        tags: [],
    });

    const [accountsConnected, setAccountsConnected] = useState();
    const [containerRegistryList, setContainerRegistryList] = useState([]);
    const [platformList, setPlatformList] = useState([]);
    const [volumeList, setVolumeList] = useState([]);
    const [projectServiceData, setProjectServiceData] = useState();
    const [repositories, setRepositories] = useState();
    const [repoList, setRepoList] = useState();
    const [loading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(true);
    const [searchValue, setSearchValue] = useState("");

    // set dropdown for platform type and volume
    useEffect(() => {
        let tempPlatform = [];
        let tempVolume = [];

        ['amd'].map((child, ind) => {  // , "arm", 'windows'
            tempPlatform.push(
                <Option key={child + ind + '!'} name="platform" value={child}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center", paddingTop: "0px" }}>
                        <p> {child} </p>
                    </div>
                </Option>
            );
        });

        [10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500].map((child, ind) => {
            tempVolume.push(
                <Option key={child + ind + '!'} name="volume" value={child}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center", paddingTop: "0px" }}>
                        <p> {child} </p>
                    </div>
                </Option>
            );
        });

        setPlatformList(tempPlatform);
        setVolumeList(tempVolume);

    }, []);

    useEffect(() => {
        console.log("currentWorkflowData in buildClickRightDrawer :", currentWorkflowData);

        let tempData = {
            // dockerRepo: currentWorkflowData?.buildConfig?.config?.docker_repo_name ? currentWorkflowData.buildConfig.config.docker_repo_name : "",
            dockerRepoUrl: currentWorkflowData ? currentWorkflowData.buildConfig.config.docker_repo_url : "",
            containerRegistry: currentWorkflowData?.buildConfig?.config?.integration_type ? (!currentWorkflowData.buildConfig.config.account_id ? (`${currentWorkflowData.buildConfig.config.alias ? currentWorkflowData.buildConfig.config.alias : currentWorkflowData.buildConfig.config.docker_repo_name}`) : `${currentWorkflowData.buildConfig.config.account_id}${currentWorkflowData.buildConfig.config.alias && `(${currentWorkflowData.buildConfig.config.alias})`} / ${currentWorkflowData.buildConfig.config.region}`) : "",
            containerRegistryId: currentWorkflowData ? currentWorkflowData?.buildConfig?.config?.container_registry_id : "",
            dockerfilePath: currentWorkflowData ? currentWorkflowData?.buildConfig?.config?.dockerfile : "",
            region: currentWorkflowData ? currentWorkflowData?.buildConfig?.config?.region : "",
            accountId: currentWorkflowData ? currentWorkflowData.buildConfig.config.account_id : "",
            alias: currentWorkflowData ? currentWorkflowData.buildConfig.config.alias : "",
            integrationType: currentWorkflowData ? currentWorkflowData.buildConfig.config.integration_type : "",
            buildContext: currentWorkflowData ? currentWorkflowData.buildConfig.config.build_context : "",
            logo: currentWorkflowData ? currentWorkflowData.buildConfig.config.container_registry_logo : "",
            platform: currentWorkflowData ? currentWorkflowData.buildConfig.config.platform : "",
            cpu: currentWorkflowData ? currentWorkflowData.buildConfig.config.cpu : "",
            ram: currentWorkflowData ? currentWorkflowData.buildConfig.config.ram : "",
            volume: currentWorkflowData ? currentWorkflowData.buildConfig.config.volume : "",
            spot: currentWorkflowData ? currentWorkflowData.buildConfig.config.spot : false,
            cacheEnabled: currentWorkflowData ? currentWorkflowData.buildConfig.config.cacheEnabled : false,
            tags: currentWorkflowData ? currentWorkflowData.buildConfig.config.image_tag : [],
        }

        // // Remove ./ prefix from dockerFilePath and buildContext
        // if(tempData.dockerfilePath && tempData.dockerfilePath.startsWith("./")){
        //     tempData.dockerfilePath = tempData.dockerfilePath.split("./")[1];
        // }
        // // Remove ./ prefix from buildContext
        // if(tempData.buildContext && tempData.buildContext.startsWith("./")){
        //     tempData.buildContext = tempData.buildContext.split("./")[1];
        // }

        // if buildContext is . then 
        if(tempData.buildContext && tempData.buildContext === "."){
            tempData.buildContext = ""
        }

        setBuildData({...tempData});

        form.setFieldsValue({
            // dockerRepo: currentWorkflowData?.buildConfig?.config?.docker_repo_name ? currentWorkflowData.buildConfig.config.docker_repo_name : "",
            dockerRepoUrl: currentWorkflowData?.buildConfig?.config?.docker_repo_url ? currentWorkflowData.buildConfig.config.docker_repo_url : "",
            containerRegistry: currentWorkflowData?.buildConfig?.config?.integration_type ? (!currentWorkflowData.buildConfig.config.account_id ? (`${currentWorkflowData.buildConfig.config.alias ? currentWorkflowData.buildConfig.config.alias : currentWorkflowData.buildConfig.config.docker_repo_name}`) : `${currentWorkflowData.buildConfig.config.account_id}${currentWorkflowData.buildConfig.config.alias && `(${currentWorkflowData.buildConfig.config.alias})`} / ${currentWorkflowData.buildConfig.config.region}`) : "",
            containerRegistryId: currentWorkflowData?.buildConfig?.config?.container_registry_id ? currentWorkflowData?.buildConfig?.config?.container_registry_id : "",
            dockerfilePath: tempData.dockerfilePath ? tempData.dockerfilePath : "",
            region: currentWorkflowData?.buildConfig?.config?.region ? currentWorkflowData?.buildConfig?.config?.region : "",
            accountId: currentWorkflowData ? currentWorkflowData.buildConfig.config.account_id : "",
            alias: currentWorkflowData ? currentWorkflowData.buildConfig.config.alias : "",
            integrationType: currentWorkflowData ? currentWorkflowData.buildConfig.config.integration_type : "",
            buildContext: tempData.buildContext ? tempData.buildContext : "",
            logo: currentWorkflowData ? currentWorkflowData.buildConfig.config.container_registry_logo : "",
            platform: currentWorkflowData ? currentWorkflowData.buildConfig.config.platform : "",
            cpu: currentWorkflowData ? currentWorkflowData.buildConfig.config.cpu : "",
            ram: currentWorkflowData ? currentWorkflowData.buildConfig.config.ram : "",
            volume: currentWorkflowData ? currentWorkflowData.buildConfig.config.volume : "",
            spot: currentWorkflowData ? currentWorkflowData.buildConfig.config.spot : false,
            cacheEnabled: currentWorkflowData ? currentWorkflowData.buildConfig.config.cacheEnabled : false,
            tags: currentWorkflowData?.buildConfig?.config?.image_tag ? currentWorkflowData.buildConfig.config.image_tag : [],
        });
    }, []);

    useEffect(() => {
        let containers = [];
        setFetching(true);
        api.get(process.env.REACT_APP_API_URI + `/api/project_env/container-registries/?account_id=${currentWorkflowData?.accountId}`).then((res) => {
            let data = res.data;
            if (data.status) {
                let tempData= {};

                data.data.map((child, ind) => {
                    containers.push(
                        <Option key={child.alias + ind + '!'} name="containerRegistry" data-registryId={child.id} data-region={child.region} data-accountId={child.account_id} data-alias={child.alias} data-integrationType={child.integration_type} data-logo={child.container_registry_logo} value={!child.account_id ? `${child.alias}` : `${child.account_id}${child.alias && `(${child.alias})`} / ${child.region}`}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center", paddingTop: "0px" }}>
                                <img src={child.integration_logo} style={{ width: "15px", height: "15px", marginRight: "10px", alignSelf: "center" }} />
                                <p> {!child.account_id ? `${child.alias}` : `${child.account_id}${child.alias && `(${child.alias})`} / ${child.region}`} </p>
                            </div>
                        </Option>
                    );

                    if(child.id === currentWorkflowData.buildConfig.config.container_registry_id){
                        console.log("entered matched");
                        tempData = {
                            ...buildData,
                            logo: child.integration_logo
                        }
                    }
                });

                // setBuildData({...tempData});
                setContainerRegistryList(containers);
                setFetching(false);
            }
            else {
                console.log("error");
                setFetching(false);
            }
        }).catch((err) => {
            setFetching(false);
            console.log("Error");
        });
    }, []);

    const handelCancel = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }

    const handelInputChange = (e) => {
        console.log("inside handelInputChange event :", e);
        let name = e.target.name;
        let value = e.target.value;

        if (['cpu', 'ram'].includes(name)) {
            setBuildData({ ...buildData, [name]: value });
        }
        else {
            setBuildData({ ...buildData, [name]: value });
        }
    }

    const handleSelectChanges = (value, event) => {
        // integration-redirect
        console.log("inside handleSelectChanges event :", event);
        console.log("inside handleSelectChanges value :", value);
        if (event.name === "containerRegistry") {
            setBuildData({
                ...buildData,
                [event.name]: value,
                containerRegistryId: event["data-registryId"],
                region: event["data-region"],
                accountId: event["data-accountId"],
                alias: event["data-alias"],
                integrationType: event["data-integrationType"],
                logo: event["data-logo"],
            });
        }
        // else if (event.name === "appSource" && event["data-key"]) {
        //     setBuildData({ ...buildData, [event.name]: event.type, dockerRepoUrl: "", repoUrl: "", accountId: event["data-key"], accountName: event["data-alias"], isPrivate: false });
        // }
        // else if ((event.name === "appSource") && (event.type === "public")) {
        //     setBuildData({ ...buildData, [event.name]: event.type, dockerRepoUrl: "", repoUrl: "", dockerFilePath: "", accountId: "", accountName: "", branch: "", isPrivate: false });
        //     return;
        // }
        // else if (event.name === "dockerRepoUrl") {
        //     console.log('event["data-url"] :', event["data-url"]);
        //     console.log('event["data-isprivate"] :', event["data-isprivate"]);
        //     setBuildData({ ...buildData, [event.name]: event.type, repoUrl: event["data-url"], isPrivate: event["data-isprivate"], isOwner: event["is-owner"] });
        // }
        // else if (event.type === "integration") {
        //     handleCancel();
        //     navigate("/integrations");
        //     return;
        // }
        else {
            setBuildData({ ...buildData, [event.name]: event.type });
        }
    }

    const handleSaveDetails = async (e) => {
        console.log("clicked handleSaveDetails...",);
        
        try{
            const values = await form.validateFields();
            console.log('Success:', values);
            
            currentWorkflowData.buildConfig.config = {
                ...currentWorkflowData.buildConfig.config,
                region: buildData.region,
                docker_repo_name: buildData.containerRegistry,
                container_registry_id: buildData.containerRegistryId,
                dockerfile: buildData.dockerfilePath,
                account_id: buildData.accountId,
                alias: buildData.alias,
                integration_type: buildData.integrationType,
                build_context: buildData.buildContext,
                container_registry_logo: buildData.logo,
                platform: buildData.platform,
                cpu: buildData.cpu,
                ram: buildData.ram,
                volume: buildData.volume,
                spot: buildData.spot,
                cacheEnabled: buildData.cacheEnabled,
            };
            
            setCurrentWorkflowData({...currentWorkflowData});
            localStorage.setItem("Workflow", JSON.stringify({...currentWorkflowData}));
            handelCancel();
        }
        catch (err) {
            console.log("Found error :", err);
        }

    }

    const handleCancel = (e) => {
        console.log("clicked handleCancel...");
        handelCancel();
    }

    // const filterReposOption = (input, option) => {
    //     if (option.key) {
    //         return (option?.props?.type?.toLowerCase() ?? '').includes(input?.toLowerCase())
    //     }
    // };

    const filterOption = (input, option) => {
        if (option.key) {
            return (option?.props.value.toLowerCase() ?? '').includes(input?.toLowerCase())
        }
    };


    return (
        <LayoutWrapper className="clusterInfo" style={{ padding: "0" }}>
            <CreateTaskHeader buttonContent={columnId} onCancel={handelCancel} />

            <div style={{ marginTop: "84px", width: "100%" }} />

            <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "28px" }}>
                <Form
                    form={form}
                    initialValues={{ ...buildData }}
                    preserve={false}
                    name="dynamic_rule"
                    style={{
                        width: "100%",
                    }}
                >

                    <Box
                        title={"Container repository*"}
                        subtitle={"Select the repository."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                    >
                        <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                            <Form.Item
                                name="containerRegistry"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Container Registry.',
                                    },
                                ]}
                            >
                                <Select
                                    value={buildData?.containerRegistry ? buildData.containerRegistry : undefined}
                                    placeholder="Select"
                                    onChange={handleSelectChanges}
                                    style={{ width: "100%" }}
                                    className="centricText"
                                    showSearch
                                    filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                    filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                    onSearch={(value) => {
                                        console.log("Enters search", value);
                                        setSearchValue(value);
                                    }}
                                    //filterOption={filterOption}
                                // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    {fetching ?
                                        <Option>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                                <Spin size="small" />
                                            </div>
                                        </Option>
                                        :
                                        containerRegistryList
                                    }
                                </Select>
                            </Form.Item>
                        </ContentHolder>
                    </Box>
                    
                    <Box
                        title={"DockerFile Path*"}
                        subtitle={"Specify the path to the Dockerfile within your repository."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                    >
                        <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                            <Form.Item
                                name="dockerfilePath"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please provide docker file path.',
                                    },
                                ]}
                            >
                                <div style={{ height: "44px" }}>
                                    <Input className="adjust-height" placeholder="Enter path" style={{height: "44px"}} addonBefore='./' value={buildData?.dockerfilePath} name="dockerfilePath" onChange={handelInputChange} />
                                </div>
                            </Form.Item>
                        </ContentHolder>
                    </Box>
                    
                    <Box
                        title={"Build Context"}
                        subtitle={"Please specify the path where the docker build command will be executed."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                    >
                        <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                            <Form.Item
                                name="buildContext"
                                rules={[]}
                            >
                                <div style={{ height: "44px" }}>
                                    <Input className="adjust-height" placeholder="Enter path" style={{height: "44px"}} addonBefore='./' value={buildData?.buildContext} name="buildContext" onChange={handelInputChange} />
                                </div>
                            </Form.Item>
                        </ContentHolder>
                    </Box>

                    <Box
                        title={"Platform*"}
                        subtitle={"Select platform type from the following."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                    >
                        <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                            <Form.Item
                                name="platform"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select platform type.',
                                    },
                                ]}
                            >
                                <Select
                                    value={buildData?.platform ? buildData.platform : undefined}
                                    placeholder="Select"
                                    onChange={handleSelectChanges}
                                    style={{ width: "100%" }}
                                    className="centricText"
                                // showSearch
                                // filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                // filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                // onSearch={(value) => {
                                //     console.log("Enters search", value);
                                //     setSearchValue(value);
                                // }}
                                //filterOption={filterOption}
                                // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    {platformList}
                                </Select>
                            </Form.Item>
                        </ContentHolder>
                    </Box>

                    <Box
                        title={"Compute Configuration*"}
                        subtitle={"Select compute type from the following."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                    >
                        <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                            <Form.Item
                                name="cpu"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please provide cpu.',
                                    },
                                    {
                                        validator: (_, value) => {
                                            if (value >= 100) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The number must be at least 100!'));
                                        }
                                    },
                                    {
                                        pattern: /^[1-9]\d*$/, // Regular expression for positive integers
                                        message: 'Please provide a positive integer.',
                                    },
                                ]}
                            >
                                <div style={{ height: "44px" }}>
                                    <Input className="adjust-height" placeholder="CPU" style={{ height: "44px" }} suffix={"m"} value={buildData?.cpu} name="cpu" onChange={handelInputChange} />
                                </div>
                            </Form.Item>
                        </ContentHolder>
                    </Box>

                    <Box
                        title={""}
                        subtitle={""}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                    >
                        <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                            <Form.Item
                                name="ram"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please provide ram.',
                                    },
                                    {
                                        validator: (_, value) => {
                                            if (value >= 100) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The number must be at least 100!'));
                                        }
                                    },
                                    {
                                        pattern: /^[1-9]\d*$/, // Regular expression for positive integers
                                        message: 'Please provide a positive integer.',
                                    },
                                    // {
                                    //     // Custom validator for positive integers
                                    //     validator: (_, value) => {
                                    //         const newPattern = /^[0-9]$/;
                                    //         console.log("newPattern.test(value) :", newPattern.test(value));

                                    //         if (!value || newPattern.test(value)) {
                                    //             console.log("resolved...");
                                    //             return Promise.resolve();
                                    //         }
                                    //         else {
                                    //             return Promise.reject(new Error('Ram should be positive digits.'));
                                    //         }
                                    //     }
                                    // }
                                ]}
                            >
                                <div style={{ height: "44px" }}>
                                    <Input className="adjust-height" placeholder="RAM" style={{ height: "44px" }} suffix={"Mi"} value={buildData?.ram} name="ram" onChange={handelInputChange} />
                                </div>
                            </Form.Item>
                        </ContentHolder>
                    </Box>

                    <Box
                        title={"Volume*"}
                        subtitle={"Select volume from the following. Volume will be in Gi."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                    >
                        <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                            <Form.Item
                                name="volume"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select volume.',
                                    },
                                ]}
                            >
                                <Select
                                    value={buildData?.volume ? buildData.volume : undefined}
                                    placeholder="Select"
                                    onChange={handleSelectChanges}
                                    style={{ width: "100%" }}
                                    className="centricText"
                                // showSearch
                                // filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                // filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                // onSearch={(value) => {
                                //     console.log("Enters search", value);
                                //     setSearchValue(value);
                                // }}
                                //filterOption={filterOption}
                                // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    {volumeList}
                                </Select>
                            </Form.Item>
                        </ContentHolder>
                    </Box>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "top" }}>
                        <Box
                            title={"Use Spot Instance*"}
                            subtitle={"Check the box to run on spot machine."}
                            titleStyle={boxTitleStyle}
                            subTitleStyle={boxSubtitleStyle}
                            style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                        />
                        <Checkbox
                            checked={buildData?.spot}
                            onChange={(e) => {
                                setBuildData(data => ({
                                    ...data,
                                    spot: e.target.checked
                                }));
                            }}
                        />
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "top" }}>
                        <Box
                            title={"Cache Enable*"}
                            subtitle={"Check the box to enable cache."}
                            titleStyle={boxTitleStyle}
                            subTitleStyle={boxSubtitleStyle}
                            style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content", marginBottom: "150px" }}
                        />

                        <Switch style={buildData?.cacheEnabled ? { backgroundColor: "#132f44" } : {}}
                            checked={buildData?.cacheEnabled}
                            onChange={(checked) => {
                                setBuildData(data => ({
                                    ...data,
                                    cacheEnabled: checked
                                }));
                            }}
                        />
                    </div>

                    {/* <Box
                        title={"Docker Repo*"}
                        subtitle={buildData?.dockerRepo}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                    />

                    <Box
                        title={"Docker Repo Url*"}
                        subtitle={buildData?.dockerRepoUrl}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                    /> */}

                    {/* Tags */}
                    {/* <Box
                        title={"Tags"}
                        subtitle={buildData?.tags?.join("")}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content", marginBottom: "100px" }}
                    /> */}

                </Form>
            </StickerWidgetWrapper>

            <CreateTaskFooter buttonContent={"Source Config"} givenButtonFunction1={handleSaveDetails} givenButtonFunction2={handleCancel} />
        </LayoutWrapper>
    );
};